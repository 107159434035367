import React from 'react';
import {copyToClipboard} from "../../../utils/informative";

function ReferralInviteResult({referred}) {
    return (
        <div className="create__referral_form">
            <div className="referral__form_title text-heading-4">Get your unique link</div>
            {/*<div className="referral__form_sub"><span>Friends receive 0 %</span> | <span>You receive 30 %</span></div>*/}
            <label htmlFor="referral__link">Referral link</label>
            <input type="text" id={'referral__link'} defaultValue={referred.link}
                   onClick={event => copyToClipboard(event)} style={{cursor: 'pointer', userSelect: "none"}}/>
            <label htmlFor="referral__link">Referral code</label>
            <input type="text" id={'referral__link'} defaultValue={referred.code}
                   onClick={event => copyToClipboard(event)} style={{cursor: 'pointer'}}/>

        </div>
    );
}

export default ReferralInviteResult;