import React from 'react';

function ReferralProfile({referralProfile, copyToClipboard, changeUser}) {
    if (!referralProfile) return null;

    return (
        <>
            <div className="single__hero_title text-heading-3">My referral code</div>

            <input className={'text-body-1'} type="text" style={{background: "white", cursor: "pointer"}}
                   onClick={event => copyToClipboard(event)} defaultValue={referralProfile.rlC
            }/>
            <div style={{display: 'flex', flexWrap: "wrap", marginTop: '20px'}}>
                <button style={{background: 'var(--border-subtle)', padding: '8px 16px'}}
                        onClick={changeUser}>Change
                </button>
            </div>
        </>
    );
}

export default ReferralProfile;
