import React, {useRef} from 'react';
import {Form} from "react-router-dom";
import {referFriendFull} from "../../../fetch/referral";

function ReferralInviteFull({data}) {
    const referralFull = useRef({
        rlFirstName: null,
        rlLastName: null,
        rlEmail: null,
        rlPhone: "",
        rrFirstName: null,
        rrLastName: null,
        rrEmail: null,
        rrPhone: "",
    });

    const submitReferralFull = (e) => {
        e.preventDefault();

        if (!Object.values(referralFull).some(r => r === null)){
            referFriendFull(referralFull, data)
        }
    }

    const changeInput = (e) => {
        referralFull.current = {...referralFull.current, [e.target.name]: e.target.value}
    }
    return (
        <section className={"modal__form_section"}>
            <div className={"referral__form_full"}>
                <div className="referral__form-wrapper">
                    <div className="referral__form">
                        <Form onSubmit={event => submitReferralFull(event)}>
                            <input
                                type="text"
                                name={"rlFirstName"}
                                required={true}
                                placeholder={"First name"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <input
                                type="text"
                                name={"rlLastName"}
                                required={true}
                                placeholder={"Last name"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <input
                                type="text"
                                name={"rlEmail"}
                                required={true}
                                placeholder={"Email"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <input
                                type="email"
                                name={"rlPhone"}
                                placeholder={"Phone (optional)"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <br/>
                            <input
                                type="text"
                                name={"rrFirstName"}
                                placeholder={"Your friend's first name"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <input
                                type="text"
                                name={"rrLastName"}
                                placeholder={"Your friend's last name"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <input
                                type="text"
                                name={"rrEmail"}
                                placeholder={"You friend's email"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <input
                                type="text"
                                name={"rrPhone"}
                                placeholder={"Your friend's phone (optional)"}
                                onChange={event => changeInput(event.target.value)}
                            />
                            <button className={'text-body-1'}>Refer a friend<i className="ri-link-m"></i></button>
                            <span className="montserrat-form form__desc">Your personal information is never distributed or sold to anyone. You can opt-out of communications at any time. View our<a
                                className="privacy__link" href="/privacy"> privacy policy</a>.</span>
                        </Form>

                    </div>
                </div>
                <div className="referral__form_full-wrapper"></div>
            </div>

        </section>
    );
}

export default ReferralInviteFull;