import React, {useEffect, useState} from "react";
import Faq from "../shared/faq";
import {Form, Link} from "react-router-dom";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {createReferral, deleteReferral, ReferralStorage} from "../../fetch/referral";
import {copyToClipboard} from "../../utils/informative";
import '../../assets/css/referral.css'
import ReferralInviteFull from "./components/referral-invite-full";


const ReferralIntro = (props) =>{
    const [referralInfo, setReferralInfo] = React.useState({
        referral__firstname: "",
        referral__lastname: "",
        referral__email: ""
    })

    const [referral, setReferral] = React.useState({
        link: null,
        code: null
    })
    const [error, setError] = useState(null)

    useEffect(() => {
        if (!localStorage.getItem('referral')){

        }else {
            let tmp_data = JSON.parse(localStorage.getItem('referral'))
            setReferral({
                ...referral,
                link:`${props.debugValue}/my-referral/${tmp_data.rlC}`,
                code: tmp_data.rlC
            })
        }
    }, []);

    const handleSubmit = (e) =>{
        e.preventDefault()
        createReferral(
            referralInfo.referral__firstname,
            referralInfo.referral__lastname,
            referralInfo.referral__email,
            props).then(rl=>{
            if(rl){
                setReferral({
                    ...referral,
                    link:`${props.debugValue}/my-referral/${rl.referral.code}`,
                    code: rl.referral.code
                })
                localStorage.setItem('referral', JSON.stringify(new ReferralStorage(rl.referral)))
            }else{
                setError('Something went wrong, please refresh the page and try again.')
            }
        })
    }

    const changeUser = () =>{
        deleteReferral()
        setReferral({
            link: null,
            code: null
        })
    }

    const handleInput = (e) =>{
        setReferralInfo({...referralInfo, [e.target.name]: e.target.value})
    }

    return(
        <>
            <HelmetProvider>
                <Helmet>
                    <title>Solarmax - Referral system</title>
                    <link rel="canonical" href={`https://solarmaxtechnology.com/referral`}/>
                </Helmet>
            </HelmetProvider>

            {/*<ReferralInviteFull data={props}/>*/}
            <section className="global__hero_single" style={{
                backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/financing_hero.jpg')}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="container_s" style={{height: '100%'}}>
                    <div className="single__hero_wrapper">
                        <div className="single__hero_text">
                            <div className="single__hero_title text-heading-3">Refer a friend and earn</div>
                            <div className="single__hero_desc  text-heading-5">Get cash for referring friends to our solar solutions and help us spread the power of clean energy!
                            </div>
                            <div className="single__hero_cta">
                                <a href={'#create__referral'}>Refer a friend</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id={'referral__introduction'} className='referral__introduction'>
                <div className="container_s">
                    <div className="referral__introduction_wrapper">
                        <div className="referral__introduction_text">
                            <div className="referral__introduction_title text-heading-4">
                                Refer a friend and get cash
                            </div>
                            <div className="referral__introduction_desc text-body-1">
                                Our referral program is a great way to earn money while helping to fight climate change.
                            </div>
                        </div>
                        <div className="referral__introduction_elements">
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referrer-1.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">How it works</div>
                                    <div className="introduction__element_desc">
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Fill in your name and email
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Share your unique referral link
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Earn money when someone buys a solar system
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/homesolution/7.1-Tax-Credit.jpeg')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Benefits</div>
                                    <div className="introduction__element_desc text-body-1">
                                        Earn $500 every time someone who clicks your link buys a solar system.
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referrer-3.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Why Refer?</div>
                                    <div className="introduction__element_desc text-body-1">
                                        You are helping reduce carbon emissions and helping your friends save money too.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className='referral__introduction_alt'>
                <div className="container_s">
                    <div className="referral__introduction_wrapper">
                        <div className="referral__introduction_text">
                            <div className="referral__introduction_title text-heading-4">
                                Tracking referrals
                            </div>

                        </div>
                        <div className="referral__introduction_elements">
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referred-1.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">How to track referrals </div>
                                    <div className="introduction__element_desc">
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                Go the “my referrals” section
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                               Enter your email address
                                            </span>
                                        </div>
                                        <div className="element__desc_single">
                                            <i className="ri-checkbox-circle-line"></i>
                                            <span className="text-body-1">
                                                See how many people have clicked, got a quote, and bought a system
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src='../../assets/media/pictures/static/blogs/article_save_big.jpg' alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Get your payment </div>
                                    <div className="introduction__element_desc text-body-1">
                                        After someone you referred has paid their deposit, we will email you. When the system is fully installed, we will reach out again to send you a check.
                                    </div>
                                </div>
                            </div>
                            <div className="referral__introduction_element">
                                <div className="introduction__element_img">
                                    <img src={require('../../assets/media/pictures/static/referred-3.png')} alt=""/>
                                </div>
                                <div className="introduction__element_text">
                                    <div className="introduction__element_title text-heading-5">Got questions?</div>
                                    <div className="introduction__element_desc text-body-1">
                                        Feel free to contact us anytime to learn about our referral rewards program and to check if your referrals are moving to a sale.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className={'my__referral_banner'} style={{
                backgroundImage: `linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0.00) 100%), url('${require('../../assets/media/pictures/static/my-referral-banner.jpeg')}')`,
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                backgroundPosition: 'center'
            }}>
                <div className="container_s">
                    <div className="my__referral_wrapper">
                        <div className="my__referral_text">
                            <div className="my__referral_title text-heading-4">Help us fight climate change </div>
                            <div className="my__referral_desc text-body-1">By helping us spread the word about going solar, you are playing a big role in reducing carbon emissions. That’s a reward we all can benefit from.
                            </div>
                        </div>
                        <div className="my__referral_cta">
                            <Link to={'/referral/dashboard'} onClick={props.handleLink}>My referrals <i className="ri-arrow-right-line"></i></Link>
                        </div>
                    </div>
                </div>
            </section>

            <section id={'create__referral'} className="create__referral">
                <div className="container">
                    <div className="create__referral_wrapper">
                        <div className="create__referral_text">
                            <div className="create__referral_title text-heading-4">Become an affiliate</div>
                            <div className="create__referral_desc text-body-1">
                                Just fill in your email and then click get your unique link. Send your unique link to
                                your friends, family, landlord, or boss, or share it on your social media accounts to
                                get the word out. Anyone who clicks it and requests a quotation is tracked and tied to
                                your account. Even if they buy a system years later, you get paid.
                            </div>
                        </div>
                        {referral.link ?
                            <>
                                <div className="create__referral_form">
                                    <div className="referral__form_title text-heading-4">Get your unique link</div>
                                    {/*<div className="referral__form_sub"><span>Friends receive 0 %</span> | <span>You receive 30 %</span></div>*/}
                                    <label htmlFor="referral__link">Referral link</label>
                                    <input type="text" id={'referral__link'} defaultValue={referral.link}
                                           onClick={event => copyToClipboard(event)}
                                           style={{cursor: 'pointer', userSelect: "none"}}/>
                                    <label htmlFor="referral__link">Referral code</label>
                                    <input type="text" id={'referral__link'} defaultValue={referral.code}
                                           onClick={event => copyToClipboard(event)} style={{cursor: 'pointer'}}/>
                                    <div style={{display: 'flex', gap: '24px', flexWrap: "wrap"}}>
                                        <button style={{background: 'var(--border-subtle)', flexGrow: 1}}
                                                onClick={changeUser}>Change
                                        </button>
                                    </div>
                                </div>
                            </>
                            :
                            <Form
                                onSubmit={event => handleSubmit(event)}
                                className="create__referral_form">
                                <div className="referral__form_title text-heading-4">Referral Link & Code</div>
                                {/*<div className="referral__form_sub"><span>Friends receive 0 %</span> | <span>You receive 30 %</span></div>*/}
                                <input
                                    className={'text-body-1'}
                                    type="text" id='referral__firstname'
                                    value={referralInfo.firstname}
                                    onChange={event => handleInput(event)}
                                    placeholder={'First name'} name={'referral__firstname'}
                                />
                                <input
                                    className={'text-body-1'}
                                    type="text" id='referral__lastname'
                                    value={referralInfo.lastname}
                                    onChange={event => handleInput(event)}
                                    placeholder={'Last name'} name={'referral__lastname'}/>
                                <input
                                    className={'text-body-1'}
                                    type="email" id='referral__email'
                                    value={referralInfo.email}
                                    onChange={event => handleInput(event)}
                                    placeholder={'Email'} name={'referral__email'}/>
                                <button className={'text-body-1'}>Get link <i className="ri-link-m"></i></button>
                                <span>{error}</span>
                            </Form>
                        }

                    </div>
                </div>
            </section>

            <Faq/>

            <section className="homepage__cta">
                <div className="container">
                    <div className="cta__wrapper">
                        <div className="cta__title text-heading-3">
                            Start your solar journey with a free quote
                        </div>
                        <div className="cta__desc text-body-1">
                            SolarMax is dedicated to providing the highest quality energy alternatives at affordable
                            prices. Enjoy easy financing options and low monthly payments that make shifting to solar
                            energy simple and stress-free.
                        </div>
                        <div className="cta__cta text-heading-6">
                            <button className={'text-heading-6'} onClick={props.toggleForm}>Get a free quote</button>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default ReferralIntro;